// extracted by mini-css-extract-plugin
export var wrapper = "AnimatedWord-module--wrapper--2eyGW";
export var anim = "AnimatedWord-module--anim---A8KN";
export var spanWrapper = "AnimatedWord-module--spanWrapper--x77UK";
export var initExit = "AnimatedWord-module--init-exit--1AD17";
export var divWrapper = "AnimatedWord-module--divWrapper--3tHPS";
export var container = "AnimatedWord-module--container--2fzSs";
export var containerInit = "AnimatedWord-module--container-init--3cda1";
export var slideEnter = "AnimatedWord-module--slide-enter--3LVEY";
export var slideEnterActive = "AnimatedWord-module--slide-enter-active--30DMK";
export var slideEnterDone = "AnimatedWord-module--slide-enter-done--2Rws3";
export var slideExit = "AnimatedWord-module--slide-exit--3kIWa";
export var slideExitActive = "AnimatedWord-module--slide-exit-active--1OQDp";
export var slideExitDone = "AnimatedWord-module--slide-exit-done--3UIwR";